import React, { useState, useEffect } from "react";
import "../Css/NavBar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../img/logo2.png";

const NavBar = () => {
  const [color, setColor] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  // Function to handle Navbar collapse
  const handleNavbarCollapse = () => {
    setExpanded(false);
  };

  return (
    <div className="nav-bar-component">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="headerlink">
                <ul>
                  <li>
                    <i className="fa fa-phone" />{" "}
                    <span>
                      <a href="tel:+91 8850340456">+91 8850340456</a> / 
                      <a href="tel:+91 9022481832">+91 9022481832</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="headerlink">
                <ul>
                <li>
                    <i className="fa fa-envelope" />{" "}
                    <span>
                      <a href="mailto:visavafarmhouse@gmail.com">visavafarmhouse@gmail.com</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="headerlink">
                <ul>
                  <li>
                    <i className="fa fa-map-marker" />{" "}
                    <span>
                      <a href="https://maps.app.goo.gl/RdDtm4FdeA8qkFbr5" target="blank">The Visawa Resort, Balasaheb ..</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="NavSection">
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          className={color ? "NavBar NavBar-bg" : "NavBar"}
          expanded={expanded}
        >
          <Container>
          <Navbar.Brand href="#home">
                <Link to="/" onClick={handleNavbarCollapse}>
                  <div className="NavLogo">
                    <img src={Logo} alt="" />
                  </div>
                </Link>
              </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => setExpanded(!expanded)}
            />
          <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-center"
            >
              <Nav>
                <Link to="/" onClick={handleNavbarCollapse}>
                  Home
                </Link>
                <Link to="/About" onClick={handleNavbarCollapse}>
                  About Us
                </Link>
                <Link to="/Room" onClick={handleNavbarCollapse}>
                  Room
                </Link>
                <Link to="/Amenities" onClick={handleNavbarCollapse}>
                  Amenities
                </Link>
                <Link to="/Gallery" onClick={handleNavbarCollapse}>
                  Gallery
                </Link>
                <Link to="/Contact" onClick={handleNavbarCollapse}>
                  Contact
                </Link>
                <Link to="https://bookings.asiatech.in/?page=7172&type=website" onClick={handleNavbarCollapse}>
                  Book Now
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default NavBar;
