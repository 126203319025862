import Deluxe from "../img/rooms/deluxeroom/deluxe1.jpg";
import SuperDeluxe from "../img/rooms/dormitory/dormitory1.jpg";
import Cottages from "../img/rooms/cottage/cottage1.jpg";
import Dormitory from "../img/rooms/dormitory/dormitory9.jpg";
import Villa from "../img/rooms/villa/villa4.jpg";



const RoomData = [
    {
        id:1,
        ServiceImage:Deluxe,
        Header:"Deluxe Rooms",
        Slugs: "/deluxe-rooms",
        ShortDesc: "The Visawa Resort is to bring together our visitor's societies and spirits with our own, communicating enthusiasm and liberality.",
        Para1: "Each Deluxe Room is carefully crafted with modern amenities and stylish decor, creating an ambiance that combines sophistication with a touch of tranquility. Enjoy the serene views of the surrounding landscape from the comfort of your room, or unwind in the well-appointed interiors designed to cater to your every need.",
        Para2: "At The Visawa Resort, we prioritize your comfort, ensuring that your stay in our Deluxe Rooms is nothing short of exceptional. From plush furnishings to state-of-the-art facilities, we invite you to immerse yourself in the epitome of luxury and make your visit a memorable one.",
        imgData: [{ roomImg: SuperDeluxe },{ roomImg: Deluxe },{ roomImg: SuperDeluxe }, { roomImg: Deluxe },{ roomImg: SuperDeluxe }, { roomImg: Deluxe }],
        images: [
            { image: Deluxe},
            { image: SuperDeluxe},
            { image: Deluxe},
            { image: SuperDeluxe},
            { image: Deluxe},
            { image: SuperDeluxe},
          ],
    },
    {
        id:2,
        ServiceImage:SuperDeluxe,
        Header:"Super Deluxe Rooms",
        Slugs: "/super-deluxe-rooms",
        ShortDesc: "The Visawa Resort is to bring together our visitor's societies and spirits with our own, communicating enthusiasm and liberality.",
        imgData: [{ roomImg: Deluxe }, { roomImg: SuperDeluxe }],
    },
    {
        id:3,
        ServiceImage:Cottages,
        Header:"Cottages",
        Slugs: "/cottages",
        ShortDesc: "The Visawa Resort is to bring together our visitor's societies and spirits with our own, communicating enthusiasm and liberality.",
        imgData: [{ roomImg: Deluxe }, { roomImg: SuperDeluxe }],
    },
    {
        id:4,
        ServiceImage:Dormitory,
        Header:"Dormitory",
        Slugs: "/dormitory",
        ShortDesc: "The Visawa Resort is to bring together our visitor's societies and spirits with our own, communicating enthusiasm and liberality.",
        imgData: [{ roomImg: SuperDeluxe }, { roomImg: Deluxe }],
    },
    {
        id:5,
        ServiceImage:Villa,
        Header:"Villa",
        Slugs: "/villa",
        ShortDesc: "The Visawa Resort is to bring together our visitor's societies and spirits with our own, communicating enthusiasm and liberality.",
        imgData: [{ roomImg: Deluxe }, { roomImg: SuperDeluxe }],
    },

]

export default RoomData;