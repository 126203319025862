import React, { useEffect } from "react";
import "../Css/Amenities.css";
import Zoom from "react-reveal/Zoom";
// ========= Aminities Images =========
import aminities1 from "../img/amenities/all/1.jpg";
import aminities2 from "../img/amenities/all/2.jpg";
import aminities3 from "../img/amenities/all/3.jpg";
import aminities4 from "../img/amenities/all/4.jpg";
import aminities5 from "../img/amenities/all/5.jpg";
import aminities6 from "../img/amenities/all/6.jpg";
import aminities7 from "../img/amenities/all/7.jpg";
import aminities8 from "../img/amenities/all/8.jpg";
import aminities9 from "../img/amenities/all/9.jpg";
import aminities10 from "../img/amenities/all/10.jpg";
import aminities11 from "../img/amenities/all/11.jpg";
// =========== Aminities Icon ========== 
import Icon1 from "../img/amenities/icon/playground.png";
import Icon2 from "../img/amenities/icon/wedding.png";
import Icon3 from "../img/amenities/icon/swimming.png";
import Icon4 from "../img/amenities/icon/child-swimming.png";
import Icon5 from "../img/amenities/icon/rain-dance.png";
import Icon6 from "../img/amenities/icon/playing.png";
import Icon7 from "../img/amenities/icon/bed.png";
import Icon8 from "../img/amenities/icon/parking-area.png";
import Icon9 from "../img/amenities/icon/live-music.png";
import Icon10 from "../img/amenities/icon/generator.png";
import Icon11 from "../img/amenities/icon/wifi.png";
// ========== Footer Import ========
import Footer from "../Components/Footer";
import { MetaTags } from "react-meta-tags";

const Menu = () => {
  return (
    <>
          {/* ========= Seo start========== */}
          <MetaTags>
        <title>The Visawa Resort | DeluxeRoom</title>
        <meta name="description" content="" />
        <meta property="og:title" content="The Visawa Resort | DeluxeRoom" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="The Visawa Resort | DeluxeRoom" />
        <link rel="canonical" href="https://oceandelight.co.in/" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== Menus Banner ======== */}
      <div className="Amenities-banner">
        <div className="AmenitiesBanner_Txt">
          <h1 className="d-none">Amenities</h1>
          <Zoom>
            <h2>Amenities</h2>
          </Zoom>
        </div>
      </div>
      <>
  {/* =========== Amenities Detail ==========*/}
  <section className="amenities-detail">
    <div className="container">
    <div class="headTitle">
          <h6>THE VISAVA RESORT</h6>
            <h2>Our Amenities</h2>
            <p>
            Visawa Resort's amenities epitomize luxury and convenience, providing guests with a seamless blend of recreational facilities, exquisite dining, and unparalleled service for an all-encompassing resort experience.
            </p>
          </div>
      <div className="row">
      <div className="col-lg-4 col-sm-6">
        <div className="services-item">
          <div className="si-pic set-bg"
          style={{backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aminities2})`,}}>
            <div className="service-icon">
              <img src={Icon1}alt="Night Camp"/>
            </div>
          </div>
        <div className="si-text">
          <h3>Children Play Area</h3>
          <p>Visawa Resort's children play area offers a delightful and safe recreational space for kids, promoting fun and creative play in a vibrant and secure environment.</p>
        </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6">
        <div className="services-item">
          <div className="si-pic set-bg"
          style={{backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aminities6})`,}}>
            <div className="service-icon">
            <img src={Icon2} alt="" />
            </div>
          </div>
        <div className="si-text">
          <h3>Wedding / Party Lawn</h3>
          <p>Visawa Resort's expansive wedding and party lawn provides a picturesque setting for unforgettable celebrations, combining lush greenery with impeccable amenities for a perfect event experience.</p>
        </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6">
        <div className="services-item">
          <div className="si-pic set-bg"
          style={{backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aminities7})`,}}>
            <div className="service-icon">
              <img src={Icon3}alt="Night Camp"/>
            </div>
          </div>
        <div className="si-text">
          <h3>Swimming Pool</h3>
          <p>Visawa Resort's inviting swimming pool beckons guests to a refreshing oasis, blending luxury and leisure for a memorable aquatic experience.</p>
        </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6">
        <div className="services-item">
          <div className="si-pic set-bg"
          style={{backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aminities5})`,}}>
            <div className="service-icon">
            <img src={Icon4} alt="" />
            </div>
          </div>
        <div className="si-text">
          <h3>Kids Swimming Pool</h3>
          <p>Visawa Resort's children's swimming pool offers a safe and enjoyable aquatic haven for young guests, ensuring a playful and delightful water experience.</p>
        </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6">
        <div className="services-item">
          <div className="si-pic set-bg"
          style={{backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aminities3})`,}}>
            <div className="service-icon">
              <img src={Icon5}alt="Night Camp"/>
            </div>
          </div>
        <div className="si-text">
          <h3>Rain Dance</h3>
          <p>Visawa Resort's exhilarating rain dance area creates a lively atmosphere, inviting guests to dance and celebrate amidst cascading water for a spirited and immersive entertainment experience.</p>
        </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6">
        <div className="services-item">
          <div className="si-pic set-bg"
          style={{backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aminities4})`,}}>
            <div className="service-icon">
            <img src={Icon6} alt="" />
            </div>
          </div>
        <div className="si-text">
          <h3>Sports lounge</h3>
          <p>Visawa Resort's sports facilities provide a dynamic space for guests to engage in recreational activities, fostering a vibrant atmosphere for fitness and leisure pursuits.</p>
        </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6">
        <div className="services-item">
          <div className="si-pic set-bg"
          style={{backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aminities1})`,}}>
            <div className="service-icon">
              <img src={Icon7}alt="Night Camp"/>
            </div>
          </div>
        <div className="si-text">
          <h3>Cafe / Resturant Area</h3>
          <p>Visawa Resort's café and restaurant area offers a delectable culinary experience in a charming ambiance, combining exquisite flavors with a relaxing setting for indulgent dining.</p>
        </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6">
        <div className="services-item">
          <div className="si-pic set-bg"
          style={{backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aminities9})`,}}>
            <div className="service-icon">
            <img src={Icon8} alt="" />
            </div>
          </div>
        <div className="si-text">
          <h3>Parking Space</h3>
          <p>Visawa Resort ensures convenient and ample parking space for guests, providing a hassle-free arrival experience with secure and accessible parking facilities.</p>
        </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6">
        <div className="services-item">
          <div className="si-pic set-bg"
          style={{backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aminities10})`,}}>
            <div className="service-icon">
            <img src={Icon9} alt="" />
            </div>
          </div>
        <div className="si-text">
          <h3>Sat &amp; Sun Live Music</h3>
          <p>Visawa Resort enhances weekend experiences with live music on Saturdays and Sundays, creating a vibrant and entertaining atmosphere for guests to enjoy.</p>
        </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6">
        <div className="services-item">
          <div className="si-pic set-bg"
          style={{backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aminities11})`,}}>
            <div className="service-icon">
              <img src={Icon10}alt="Night Camp"/>
            </div>
          </div>
        <div className="si-text">
          <h3>Generator Backup</h3>
          <p>Visawa Resort ensures uninterrupted comfort with generator backup, offering reliable power supply to ensure a seamless and enjoyable stay for guests.</p>
        </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6">
        <div className="services-item">
          <div className="si-pic set-bg"
          style={{backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aminities8})`,}}>
            <div className="service-icon">
            <img src={Icon11} alt="" />
            </div>
          </div>
        <div className="si-text">
          <h3>Free WiFi</h3>
          <p>Visawa Resort provides seamless connectivity with complimentary WiFi, enhancing the guest experience with reliable and high-speed internet access.</p>
        </div>
        </div>
      </div>
      </div>
    </div>
  </section>
</>

      <Footer />
    </>
  );
};

export default Menu;
