import React, { useRef, useState, useCallback } from "react";
import "../Css/Room.css";
import Footer from "../Components/Footer";
import { Zoom } from "react-reveal";
import { MetaTags } from "react-meta-tags";
import RoomData from "../Components/RoomData";
import EnquiryModal from '../Components/EnquiryModal';
import { Link } from "react-router-dom";

const Room = () => {
   // popup Modal
   const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
          {/* ========= Seo start========== */}
          <MetaTags>
        <title>The Visawa Resort | DeluxeRoom</title>
        <meta name="description" content="" />
        <meta property="og:title" content="The Visawa Resort | DeluxeRoom" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="The Visawa Resort | DeluxeRoom" />
        <link rel="canonical" href="https://oceandelight.co.in/" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== Room Banner ======== */}
      <div className="Room-banner">
        <div className="RoomBanner_Txt">
        <h1 className="d-none">Rooms</h1>
          <Zoom>
            <h2>Rooms</h2>
          </Zoom>
        </div>
      </div>
      {/* ============== Room Section ========== */}
      <section className="room-section">
        <div className="container">
          {RoomData.map((item, index) => {
              const { id, ServiceImage, Header, ShortDesc, Slugs} = item;
              const isEvenRow = index % 2 === 0;
              return (
                <>
                <div className="row" key={id}>
                <div className={`col-md-6 mt-3 ${isEvenRow ? 'order-md-2 order-1' : 'order-1'}`}>
                <div className="room-img">
                  <img src={ServiceImage} alt="" />
                </div>
                </div>
                <div className={`col-md-6 mt-3 ${isEvenRow ? 'order-md-1 order-2' : 'order-2'}`}>
                  <div className="room-details">
                    <div className="room-Desc">
                      <h2>{Header}</h2>
                      <p>{ShortDesc}</p>
                    </div>
                    <div className="icon-list">
                      <ul>
                        <li>
                          <i className="fa fa-bed" />2 beds
                        </li>
                        <li>
                          <i className="fa fa-bath" />1 Bathroom
                        </li>
                        <li>
                          <i className="fa fa-cutlery" />Break
                        </li>
                      </ul>
                    </div>
                    <div className="link-btn">
                    <Link to={`${Slugs}`} className="theme-btn btn-style-one btn-md"> <span>room details</span></Link>
                    </div>
                  </div>
                </div>
                </div>
                </>
              );
            })}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Room;
