import React, { useState } from "react";
import "../Css/Contact.css";
import Footer from "../Components/Footer";
import { MetaTags } from "react-meta-tags";
import Zoom from "react-reveal/Zoom";
import $ from "jquery";

const Contact = () => {
  const [errors, setErrors] = useState({
    custName: "",
    custEmail: "",
    custContact: "",
    custMessage: "",
  });

  const handleValidation = () => {
    let isValid = true;
    const newErrors = {
      custName: "",
      custEmail: "",
      custContact: "",
      custPerson: "",
      custMessage: "",
    };
    if (!custName.trim()) {
      isValid = false;
      newErrors.custName = "Name is required";
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!custEmail.match(emailRegex)) {
      isValid = false;
      newErrors.custEmail = "Email is not valid";
    }

    if (!custContact.trim()) {
      isValid = false;
      newErrors.custContact = "Phone is required";
    } else if (!/^\d{10}$/.test(custContact)) {
      isValid = false;
      newErrors.custContact = "Phone must have 10 digits";
    }
    if (!custPerson.trim()) {
      isValid = false;
      newErrors.custPerson = "Enter Total People";
    }

    if (!custMessage.trim()) {
      isValid = false;
      newErrors.custMessage = "Write a Message";
    }

    setErrors(newErrors);
    return isValid;
  };
  // ============ Mail function ==========
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custPerson, setCustPerson] = useState("");
  const [custMessage, setCustMessage] = useState("");
  // ============== Mail Print ======
  const ServForm = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      var body =
        '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#6e3b70">Ocean Delight</h2><p>Hello Ocean Delight,</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custName +
        '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custEmail +
        '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custContact +
        '</td></tr><tr><td style="text-align:left"><strong>Total People:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custPerson +
        '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custMessage +
        '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#6e3b70;padding-left:5px">Ocean Delight</span>.</p></div></body></html>';
      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          toEmail: "oceandelight2022@gmail.com",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName: "Ocean-Delight",
          accountLeadSource: "https://oceandelight.co.in/",
          accountLeadName: custName,
          // accountLeadEmail: custEmail,
        },
        function (dataa, status) {
          console.log("data :" + dataa);
          console.log("name:" + custName);
          console.log("name:" + custEmail);
        }
      );

      alert("Your Form has Submitted Our team will contact with You  soon.");
      e.preventDefault();
      return false;
    }
  };
  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>The Visawa Resort | DeluxeRoom</title>
        <meta name="description" content="" />
        <meta property="og:title" content="The Visawa Resort | DeluxeRoom" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="The Visawa Resort | DeluxeRoom" />
        <link rel="canonical" href="https://oceandelight.co.in/" />
      </MetaTags>
      {/* ======== Seo end ======= */}

      {/* ========== Contact Banner ======== */}
      <div className="Contact-banner">
        <div className="ContactBanner-Txt">
          <h1 className="d-none">Contact</h1>
          <Zoom>
            <h2>Contact</h2>
          </Zoom>
          {/* <p>
          For more details, please contact our resort in Bhogave.
          </p> */}
        </div>
      </div>

      {/* ========== Contact Address Section =========== */}
      {/* <section className="contactSection">
        <div className="container">
          <div className="site-heading text-center">
            <Bounce>
              <h2 className="site-title">Stay Connection</h2>
            </Bounce>
            <div className="header-img-divider">
              <img src={Arrow} alt="birthday" />
            </div>
            <h4>Glance at our great resort</h4>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Bounce>
                <div className="media-contact">
                  <h2 className="mb-4">Get In Touch</h2>
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Address</h4>
                        <p>
                          <a href="">
                            At Post Bhogwe, Nr Mahapurush Mandir,
                            Dist.Sindhudurg, Tal. Vengurla, Maharashtra 416523
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Contact</h4>
                        <p>
                          <a href="tel:+91 9022481832">+91 9022481832</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Mail</h4>
                        <p>
                          <a href="mailto:oceandelight2022@gmail.com">
                            oceandelight2022@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Times</h4>
                        <p>
                          Mon to Fri : 8.00am to 5.00 pm <br />
                          Sat : 8.00am to 3.00 pm
                          <br /> Sun : closed
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Bounce>
            </div>
            <div className="col-md-8">
              <div className="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Name*</label>
                      <div className="input-group">
                        <input
                          id="name"
                          type="text"
                          placeholder=""
                          className="form-control"
                          name="name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Email*</label>
                      <div className="input-group">
                        <input
                          type="email"
                          placeholder=""
                          className="form-control"
                          name="email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Phone*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder=""
                          className="form-control"
                          name="phone"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Total Person*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder=""
                          className="form-control"
                          name="website"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Check In*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder=""
                          className="form-control"
                          name="website"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Check Out*</label>
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder=""
                          className="form-control"
                          name="website"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="form-label">Message*</label>
                  <div className="input-group">
                    <textarea
                      className="form-control input-message"
                      placeholder="Comment*"
                      rows={7}
                      name="message"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* =========== Contact Section ========== */}
      <section className="Contact-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 contact-column">
              <div className="left">
                <div className="left-Txt">
                  <span className="left-title">Stay With Us</span>
                  <h2>Contact Detail</h2>
                </div>
                <div className="contact-form">
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Address</h4>
                        <p>
                          <a href="https://maps.app.goo.gl/RdDtm4FdeA8qkFbr5">
                            The Visawa Resort, Balasaheb Mhatre Collage Rd,
                            Badlapur.Maharashtra 421503
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Contact</h4>
                        <p>
                          <a href="tel:+91 8850340456">+91 8850340456</a><br />
                          <a href="tel:+91 9022481832">+91 9022481832</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Mail</h4>
                        <p>
                          <a href="mailto:visavafarmhouse@gmail.com">
                            visavafarmhouse@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Times</h4>
                        <p>
                          Mon to Fri : 8.00am to 5.00 pm <br />
                          Sat : 8.00am to 3.00 pm
                          <br /> Sun : closed
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 contact-content">
              <div className="right">
                <div className="right-Txt">
                  <h2>Enquiry Now</h2>
                </div>
                <div className="contact-form">
                  <div className="row">
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Your Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={custName}
                          onChange={(e) => setCustName(e.target.value)}
                        />
                        {errors.custName && (
                          <span className="error-text">{errors.custName}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Your Email</label>
                        <input
                          class="form-control"
                          type="text"
                          name="email"
                          placeholder="Email"
                          value={custEmail}
                          onChange={(e) => setCustEmail(e.target.value)}
                        />
                        {errors.custEmail && (
                          <span className="error-text">{errors.custEmail}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Phone</label>
                        <input
                          className="form-control"
                          type="number"
                          name="contact"
                          placeholder="Contact No"
                          value={custContact}
                          onChange={(e) => setCustContact(e.target.value)}
                        />
                        {errors.custContact && (
                          <span className="error-text">
                            {errors.custContact}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Total Person</label>
                        <input
                          className="form-control"
                          type="number"
                          name="person"
                          placeholder="Total People"
                          value={custPerson}
                          onChange={(e) => setCustPerson(e.target.value)}
                        />
                        {errors.custContact && (
                          <span className="error-text">
                            {errors.custPerson}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div class="form-group">
                        <label for="name">Message</label>
                        <textarea
                          class="form-control"
                          name="message"
                          placeholder="Message"
                          id="custMessage1"
                          value={custMessage}
                          rows={6}
                          onChange={(e) => setCustMessage(e.target.value)}
                        ></textarea>
                        {errors.custMessage && (
                          <span className="error-text">
                            {errors.custMessage}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div class="form-btn">
                        <button className="booking-btn" onClick={ServForm}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========= Contact Map =========== */}
      <div className="contact-map mt-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3769.1080966721565!2d73.279089!3d19.146745!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7f2a0312f473d%3A0xcaca56ceaecea4b9!2sThe%20Visawa%20Resort!5e0!3m2!1sen!2sin!4v1701857671388!5m2!1sen!2sin"
          width="100%"
          height="100%"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
