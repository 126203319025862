import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay} from "swiper/modules";
import "../Css/About.css";
import Footer from "../Components/Footer";
import CountUp from "react-countup";
import { MetaTags } from "react-meta-tags";
import About1 from "../img/about/about3.jpg";
import Zoom from "react-reveal/Zoom";
import Bounce from "react-reveal/Bounce";

const About = () => {
  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>The Visawa Resort | Deluxe Room</title>
        <meta name="description" content="" />
        <meta property="og:title" content="The Visawa Resort | DeluxeRoom" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="The Visawa Resort | DeluxeRoom" />
        <link rel="canonical" href="https://oceandelight.co.in/" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== About Banner ==== */}
      <div className="About-banner">
        <div className="AboutBanner-Txt">
          <h1 className="d-none">About Us</h1>
          <Zoom>
            <h2>About Us</h2>
          </Zoom>
          {/* <p>
            The Visawa Resort | DeluxeRoom in bhogave, a beautiful beach resort in a
            tropical paradise.
          </p> */}
        </div>
      </div>
      {/* =========== About Section =========== */}
      <section className="about-section">
        <div className="container">
          <div className="row">
          <div className="col-lg-6">
            <div className="sec-title">
                  <span className="sub-title">The Visawa Resort</span>
                  <h2>Welcome To The Visawa Resort Lawns & Banquet</h2>
                  <p>At the Visawa Resort, we are dedicated to providing exceptional experiences for our guests. Situated in the serene surroundings of Badlapur, our resort offers a perfect blend of tranquility and luxury. Whether you're seeking a relaxing retreat, an adventurous escape, or a professional event, we have it all covered.</p>
                  <p>Our resort features a variety of accommodations designed to cater to every preference. From spacious rooms to cozy farmhouses, we offer comfortable and stylish spaces that will make you feel right at home. Wake up to breathtaking views and immerse yourself in the beauty of nature during your stay.</p>
                  <p>In addition to our beautiful accommodations, we take pride in providing exceptional Amenities. Take a refreshing dip in our swimming pool, dance to your heart's content in our rain dance area, and indulge in mouthwatering culinary delights at our on-site restaurant. We strive to ensure that every aspect of your stay is filled with delight and relaxation.</p>
                  <p>Moreover, we understand the importance of hosting successful conferences and corporate events. That's why we offer a state-of-the-art conference hall that provides the ideal setting for business gatherings, meetings, and seminars. Equipped with modern facilities and supported by our attentive staff, our conference hall is designed to meet all your professional needs.</p>
                  <div className="AboutTxt1">
                    <p>At the Visawa Resort, our dedicated team is committed to providing exceptional service and ensuring that your stay with us is nothing short of extraordinary. From the moment you arrive until the time of your departure, we are here to make your experience unforgettable.</p>
                    <p>Whether you're seeking a rejuvenating vacation, a memorable family gathering, or a productive corporate event, the Visawa Resort promises an unparalleled experience. Come and immerse yourself in luxury, tranquility, and natural beauty at our resort. Your journey begins here.</p>
                    <p>Discover the magic of the Visawa Resort. We eagerly await the opportunity to welcome you and create memories that will last a lifetime.</p>
                  </div>
                </div>
            </div>
            <div className="col-lg-6">
              <div className="AboutImg">
                <img src={About1} alt="" />
              </div>
              <div className="AboutTxt">
              <p>At the Visawa Resort, our dedicated team is committed to providing exceptional service and ensuring that your stay with us is nothing short of extraordinary. From the moment you arrive until the time of your departure, we are here to make your experience unforgettable.</p>
                    <p>Whether you're seeking a rejuvenating vacation, a memorable family gathering, or a productive corporate event, the Visawa Resort promises an unparalleled experience. Come and immerse yourself in luxury, tranquility, and natural beauty at our resort. Your journey begins here.</p>
                    <p>Discover the magic of the Visawa Resort. We eagerly await the opportunity to welcome you and create memories that will last a lifetime.</p>
                    </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== Counter Section ============= */}
      <section className="counter-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="counter_txt">
                <Bounce>
                  <h2>The Best Resort Ever</h2>
                </Bounce>
                <p>At the Visawa Resort, we are dedicated to providing exceptional experiences for our guests. Situated in the serene surroundings of Badlapur, our resort offers a perfect blend of tranquility and luxury. Whether you're seeking a relaxing retreat, an adventurous escape, or a professional event, we have it all covered.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-6 mb-5">
                  <div className="counter left_icn">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                    <span className="timer">
                      <span>
                        <CountUp end={15} duration={10} />
                      </span>
                    </span>
                    <label>Total Experience</label>
                  </div>
                </div>
                <div className="col-6 mb-5">
                  <div className="counter left_icn">
                    <i class="fa fa-users" aria-hidden="true"></i>
                    <span className="timer">
                      <span>
                        <span>
                          <CountUp end={1500} duration={5} />
                        </span>
                      </span>
                    </span>
                    <label>Total Client</label>
                  </div>
                </div>
                <div className="col-6 mb-5">
                  <div className="counter left_icn">
                    <i className="fa fa-smile-o" aria-hidden="true" />
                    <span className="timer">
                      <span>
                        <span>
                          <CountUp end={1342} duration={5} />
                        </span>
                      </span>
                    </span>
                    <label>Happy User</label>
                  </div>
                </div>
                <div className="col-6 mb-5">
                  <div className="counter left_icn">
                    <i className="fa fa-comments" aria-hidden="true" />
                    <span className="timer">
                      <span>
                        <span>
                          <CountUp end={956} duration={5} />
                        </span>
                      </span>
                    </span>
                    <label>People Review</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========  Testimonal Section ============  */}
      <section className="testimonalSection">
        <div className="container">
        <div class="headTitle">
          <h6>THE VISAVA RESORT</h6>
            <h2>What Our Guest's Say</h2>
            <p>
            Visawa Resort's testimonials capture the genuine and heartfelt expressions of our satisfied guests, reflecting the exceptional hospitality, memorable experiences, and overall satisfaction that define our resort.
            </p>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay]}
            className="testimonalSwiper"
          >
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "The premises are made nicely and also maintained nicely with good cleanliness around. Staff is cooperative and prompt in addressing. They have two lawns to cater different size of functions like marriage or reception. The food is also decent. Parking space may be inadequate if a large function is there and many cars are visiting."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="" />
                  </div>
                  <div className="client_name">
                    <h5>Ashish Shinde</h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "Beautiful place to relax. Rooms and the whole premises are very clean and green. Staff are very cooperative. Canteen staff too good at your service all the time."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="" />
                  </div>
                  <div className="client_name">
                    <h5>Pooja Jawale</h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>"Visawa resort is very pleasant, easy to reach place for weekend gateways, celebrate your special days. They have executive rooms, cottages , as well as dormitory all having A. C and all the basic room amenities. All the rooms are very neat and clean."</p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="" />
                  </div>
                  <div className="client_name">
                  <h5>Gauri Sontakke</h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* ========== Footer ========== */}
      <Footer />
    </>
  );
};

export default About;
