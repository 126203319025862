import React, { useRef, useState, useCallback } from "react";
import "../Css/Home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "../Css/Home.css";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import About1 from "../img/rooms/82.jpg";
import About2 from "../img/about/about3.jpg";
import Zoom from "react-reveal/Zoom";
import Footer from "../Components/Footer";
import Service2 from "../img/gallery/gallery1.jpg";
import Service3 from "../img/gallery/gallery10.jpg";
import Service6 from "../img/gallery/gallery11.jpg";
import Flip from "react-reveal/Flip";
import $ from "jquery";
import EnquiryModal from "../Components/EnquiryModal";
import RoomData from "../Components/RoomData";
// ======= Amenities Icons ========
import KidsPlaying from "../img/amenities/icon/playground.png";
import ParkingSpace from "../img/amenities/icon/parking-area.png";
import CafeResturant from "../img/amenities/icon/bed.png";
import SwimmingPool from "../img/amenities/icon/swimming.png";
import FreeWiFi from "../img/amenities/icon/wifi.png";
import SportsLounge from "../img/amenities/icon/playing.png";
import KidsSwimming from "../img/amenities/icon/child-swimming.png";
import WeddingLawn from "../img/amenities/icon/wedding.png";
import RainDance from "../img/amenities/icon/rain-dance.png";
// =========== wedding ==========
import Wedding from "../img/wedding/photocol.png";

const Home = () => {
  const [errors, setErrors] = useState({
    custName: "",
    custEmail: "",
    custContact: "",
    custMessage: "",
  });

  const handleValidation = () => {
    let isValid = true;
    const newErrors = {
      custName: "",
      custEmail: "",
      custContact: "",
      custPerson: "",
      custMessage: "",
    };
    if (!custName.trim()) {
      isValid = false;
      newErrors.custName = "Name is required";
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!custEmail.match(emailRegex)) {
      isValid = false;
      newErrors.custEmail = "Email is not valid";
    }

    if (!custContact.trim()) {
      isValid = false;
      newErrors.custContact = "Phone is required";
    } else if (!/^\d{10}$/.test(custContact)) {
      isValid = false;
      newErrors.custContact = "Phone must have 10 digits";
    }
    if (!custPerson.trim()) {
      isValid = false;
      newErrors.custPerson = "Enter Total People";
    }

    if (!custMessage.trim()) {
      isValid = false;
      newErrors.custMessage = "Write a Message";
    }

    setErrors(newErrors);
    return isValid;
  };
  // ============ Mail function ==========
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custPerson, setCustPerson] = useState("");
  const [custMessage, setCustMessage] = useState("");
  // ============== Mail Print ======
  const ServForm = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      var body =
        '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#6e3b70">Ocean Delight</h2><p>Hello Ocean Delight,</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custName +
        '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custEmail +
        '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custContact +
        '</td></tr><tr><td style="text-align:left"><strong>Total People:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custPerson +
        '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custMessage +
        '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#6e3b70;padding-left:5px">Ocean Delight</span>.</p></div></body></html>';
      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          toEmail: "oceandelight2022@gmail.com",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName: "Ocean-Delight",
          accountLeadSource: "https://oceandelight.co.in/",
          accountLeadName: custName,
          // accountLeadEmail: custEmail,
        },
        function (dataa, status) {
          console.log("data :" + dataa);
          console.log("name:" + custName);
          console.log("name:" + custEmail);
        }
      );

      alert("Your Form has Submitted Our team will contact with You  soon.");
      e.preventDefault();
      return false;
    }
  };
  // popup Modal
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>The Visawa Resort | DeluxeRoom</title>
        <meta name="description" content="" />
        <meta property="og:title" content="The Visawa Resort | DeluxeRoom" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="The Visawa Resort | DeluxeRoom" />
        <link rel="canonical" href="https://oceandelight.co.in/" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ============ Banner Section =========== */}
      <div className="bannerSection">
        <Swiper
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="Home_Banner"
        >
          <SwiperSlide>
            <div className="home_Banner1">
              <div className="homeBanner_Txt">
                <h1 className="d-none">Private Beach</h1>
                <div className="banner-star">
                  <ul>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                  </ul>
                </div>
                <Zoom>
                  <h6>The Best Resort In BadlaPur</h6>
                </Zoom>
                <Zoom>
                  <h2>The Visawa Resort</h2>
                </Zoom>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="home_Banner2">
              <div className="homeBanner_Txt">
                <div className="banner-star">
                  <ul>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                  </ul>
                </div>
                <Zoom>
                  <h6>The Best Resort In BadlaPur</h6>
                </Zoom>
                <Zoom>
                  <h2>The Visawa Resort</h2>
                </Zoom>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="home_Banner3">
              <div className="homeBanner_Txt">
                <div className="banner-star">
                  <ul>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                    <li>
                      <i className="fa fa-star" />
                    </li>
                  </ul>
                </div>
                <Zoom>
                  <h6>The Best Resort In BadlaPur</h6>
                </Zoom>
                <Zoom>
                  <h2>The Visawa Resort</h2>
                </Zoom>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* =========== About Section =========== */}
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="aboutImg col-xl-6 col-lg-6">
              <div className="home-about">
                <div className="img1">
                  <img src={About2} alt="" />
                </div>
                <div className="img2">
                  <img src={About1} alt="" />
                </div>
              </div>
            </div>
            <div className="content-column col-lg-6">
              <div className="inner-column">
                <div className="about-txt">
                  <span>THE VISAWA RESORT</span>
                  <h2>Enjoy a Luxury Experience</h2>
                  <p>
                    At the Visawa Resort, we are dedicated to providing
                    exceptional experiences for our guests. Situated in the
                    serene surroundings of Badlapur, our resort offers a perfect
                    blend of tranquility and luxury. Whether you're seeking a
                    relaxing retreat, an adventurous escape, or a professional
                    event, we have it all covered.
                  </p>
                  <p>
                    Our resort features a variety of accommodations designed to
                    cater to every preference. From spacious rooms to cozy
                    farmhouses, we offer comfortable and stylish spaces that
                    will make you feel right at home. Wake up to breathtaking
                    views and immerse yourself in the beauty of nature during
                    your stay.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========= Room Services Section ========= */}
      <section className="room-service">
        <div className="container">
          <div class="headTitle">
            <h6>THE VISAVA RESORT</h6>
            <h2>Rooms & Suites</h2>
            <p>
              Visawa Resort's well-appointed rooms and suites offer a blend of
              comfort and luxury, providing guests with an inviting and tranquil
              retreat for a restful stay.
            </p>
          </div>
          <div className="row">
            {RoomData.map((item, index) => {
              const { id, ServiceImage, Header, Slugs } = item;
              return (
                <>
                  <div className="col-md-4">
                    <div className="room-item">
                      <div className="home-room-img">
                        <img src={ServiceImage} alt="" />
                      </div>
                      <span className="category">
                        <button
                          type="submit"
                          onClick={() => setModalShow(true)}
                        >
                          Book
                        </button>
                        <EnquiryModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </span>
                      <div className="con">
                        <h5>
                          <Link to={`${Slugs}`}>{Header}</Link>
                        </h5>
                        <div className="line" />
                        <div className="row facilities">
                          <div className="col col-md-7">
                            <ul>
                              <li>
                                <i className="fa fa-bath"></i>
                              </li>
                              <li>
                                <i className="fa fa-bed"></i>
                              </li>
                              <li>
                                <i className="fa fa-cutlery"></i>
                              </li>
                              <li>
                                <i className="fa fa-hands-wash"></i>
                              </li>
                            </ul>
                          </div>
                          <div className="col col-md-5 text-end">
                            <div className="permalink">
                              <Link to={`${Slugs}`}>
                                Details <i className="fa fa-arrow-right" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      {/* ========= AminitieSection ============  */}
      <section className="aminitiesSection">
        <div className="container">
          <div class="headTitle">
            <h6>THE VISAVA RESORT</h6>
            <h2>Our Amenities</h2>
            <p>
              Visawa Resort's amenities epitomize luxury and convenience,
              providing guests with a seamless blend of recreational facilities,
              exquisite dining, and unparalleled service for an all-encompassing
              resort experience.
            </p>
          </div>
          <div className="row row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2">
            <div className="col mb-md-3 mb-2 ">
              <div className="aminitiCard">
                <div className="aminiti-Icon">
                  <img src={KidsPlaying} alt="" />
                </div>
                <h3>Children Play Area</h3>
              </div>
            </div>
            <div className="col mb-md-3 mb-2 ">
              <div className="aminitiCard">
                <div className="aminiti-Icon">
                  <img src={ParkingSpace} alt="" />
                </div>
                <h3>Parking Space</h3>
              </div>
            </div>
            <div className="col mb-md-3 mb-2 ">
              <div className="aminitiCard">
                <div className="aminiti-Icon">
                  <img src={CafeResturant} alt="" />
                </div>
                <h3>Cafe / Resturant Area</h3>
              </div>
            </div>
            <div className="col mb-md-3 mb-2 ">
              <div className="aminitiCard">
                <div className="aminiti-Icon">
                  <img src={SwimmingPool} alt="" />
                </div>
                <h3>Swimming Pool</h3>
              </div>
            </div>
            <div className="col mb-md-3 mb-2 ">
              <div className="aminitiCard">
                <div className="aminiti-Icon">
                  <img src={FreeWiFi} alt="" />
                </div>
                <h3>Free WiFi</h3>
              </div>
            </div>
            <div className="col mb-md-3 mb-2 ">
              <div className="aminitiCard">
                <div className="aminiti-Icon">
                  <img src={SportsLounge} alt="" />
                </div>
                <h3>Sports lounge</h3>
              </div>
            </div>
            <div className="col mb-md-3 mb-2 ">
              <div className="aminitiCard">
                <div className="aminiti-Icon">
                  <img src={KidsSwimming} alt="" />
                </div>
                <h3>Kids Swimming Pool</h3>
              </div>
            </div>
            <div className="col mb-md-3 mb-2 ">
              <div className="aminitiCard">
                <div className="aminiti-Icon">
                  <img src={WeddingLawn} alt="" />
                </div>
                <h3>Wedding / Party Lawn</h3>
              </div>
            </div>
            <div class="col mb-md-3 mb-2 ">
              <div class="aminitiCard">
                <div className="aminiti-Icon">
                  <img src={RainDance} alt="" />
                </div>
                <h3>Rain Dance</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========= Wedding / Party Lawn Section ========= */}
      <section className="wedding-lawn">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-md-1 order-2">
              <div className="wedding-img">
                <img src={Wedding} alt="" />
              </div>
            </div>
            <div className="col-md-6 order-md-2 order-1">
              <div className="Resort_txt">
                <h6>THE VISAVA RESORT</h6>
                <div className="resortHead">
                  <h2>Wedding / Party Lawn</h2>
                </div>
                <div className="resortPara">
                  <p>
                    At our resort, we offer a magnificent wedding and party lawn
                    that provides the perfect setting for your special
                    celebrations. Our beautiful and spacious outdoor venue is
                    designed to create unforgettable memories for you and your
                    guests.From the moment you arrive until the time of your
                    departure, we are here to make your experience
                    unforgettable.
                  </p>
                  <p>
                    Lawn Capacity : 800 to 1000 people
                    <br /> AC Banquet Hall Capacity : 100 people
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======= Promotional Video ======== */}
      <section className="video-wrapper video bg-img bg-fixed">
        <div className="container">
          <div class="headTitle">
            <h6>THE VISAVA RESORT</h6>
            <h2>Promotional Video</h2>
            <p>
              Visawa Resort's promotional video encapsulates the essence of our
              luxury retreat, showcasing stunning visuals and immersive
              experiences to invite guests into a world of unparalleled
              hospitality and relaxation.
            </p>
          </div>
          <div className="row">
            <div className="text-center col-md-12">
              <a className="vid" href="https://youtu.be/WCRe05iao_8">
                <div className="vid-butn">
                  <span className="icon">
                    <i className="fa fa-play" />
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* =========  Attrection Section ============  */}
      {/* <section className="nearbyAttrection">
        <div className="container">
          <div class="headTitle">
            <h6>THE VISAVA RESORT</h6>
            <h2>Near By Attraction</h2>
            <p>
              Visawa Resort's proximity to nearby attractions offers guests the
              convenience of exploring captivating landmarks, ensuring a
              well-rounded experience of both leisure and local culture during
              their stay!
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-12 mt-md-5 mt-3">
              <Flip left>
                <div
                  className="attrectionCard aos-init aos-animate"
                  data-aos="zoom-in-up"
                >
                  <div className="attrectionImg">
                    <img src={Service2} alt=" Resort Near Beach " />
                    <h3>Lorem, ipsum.</h3>
                  </div>
                  <div className="attractionBody">
                    <h3>Lorem, ipsum.</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Exercitationem, assumenda est earum magnam quidem
                      doloribus? Distinctio repellendus dolore sunt pariatur?
                    </p>
                  </div>
                </div>
              </Flip>
            </div>
            <div className="col-lg-4 col-12 mt-md-5 mt-3">
              <Flip left>
                <div
                  className="attrectionCard aos-init aos-animate"
                  data-aos="fade-left"
                >
                  <div className="attrectionImg">
                    <img src={Service3} alt=" Resort Near Temple" />
                    <h3>Lorem, ipsum.</h3>
                  </div>
                  <div className="attractionBody">
                    <h3>Lorem, ipsum.</h3>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Ex magni delectus et dolorum velit obcaecati nisi quam
                      necessitatibus quidem a.
                    </p>
                  </div>
                </div>
              </Flip>
            </div>
            <div className="col-lg-4 col-12 mt-md-5 mt-3">
              <Flip left>
                <div
                  className="attrectionCard aos-init aos-animate"
                  data-aos="fade-left"
                >
                  <div className="attrectionImg">
                    <img src={Service6} alt=" Resort Near Temple" />
                    <h3>Lorem, ipsum.</h3>
                  </div>
                  <div className="attractionBody">
                    <h3>Lorem, ipsum.</h3>
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Odio nulla laudantium corrupti! Ut qui nemo nulla numquam,
                      laborum accusamus quae!
                    </p>
                  </div>
                </div>
              </Flip>
            </div>
          </div>
        </div>
      </section> */}
      {/* =========  Testimonal Section ============  */}
      <section className="testimonalSection">
        <div className="container">
          <div class="headTitle">
            <h6>THE VISAVA RESORT</h6>
            <h2>What Our Guest's Say</h2>
            <p>
              Visawa Resort's testimonials capture the genuine and heartfelt
              expressions of our satisfied guests, reflecting the exceptional
              hospitality, memorable experiences, and overall satisfaction that
              define our resort.
            </p>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay]}
            className="testimonalSwiper"
          >
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "The premises are made nicely and also maintained nicely
                    with good cleanliness around. Staff is cooperative and
                    prompt in addressing. They have two lawns to cater different
                    size of functions like marriage or reception. The food is
                    also decent. Parking space may be inadequate if a large
                    function is there and many cars are visiting."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About2} alt="" />
                  </div>
                  <div className="client_name">
                    <h5>Ashish Shinde</h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "Beautiful place to relax. Rooms and the whole premises are
                    very clean and green. Staff are very cooperative. Canteen
                    staff too good at your service all the time."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About2} alt="" />
                  </div>
                  <div className="client_name">
                    <h5>Pooja Jawale</h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "Visawa resort is very pleasant, easy to reach place for
                    weekend gateways, celebrate your special days. They have
                    executive rooms, cottages , as well as dormitory all having
                    A. C and all the basic room amenities. All the rooms are
                    very neat and clean."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About2} alt="" />
                  </div>
                  <div className="client_name">
                    <h5>Gauri Sontakke</h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* =========== Form  Section ========== */}
      <section className="booking-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 booking-column">
              <div className="booking-left">
                <div className="booking-left-Txt">
                  <span>Stay With Us</span>
                  <h2>Contact Detail</h2>
                </div>
                <div className="booking-form">
                  <div className="row">
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Your Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          value={custName}
                          onChange={(e) => setCustName(e.target.value)}
                        />
                        {errors.custName && (
                          <span className="error-text">{errors.custName}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Your Email</label>
                        <input
                          class="form-control"
                          type="text"
                          name="email"
                          value={custEmail}
                          onChange={(e) => setCustEmail(e.target.value)}
                        />
                        {errors.custEmail && (
                          <span className="error-text">{errors.custEmail}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Phone</label>
                        <input
                          className="form-control"
                          type="number"
                          name="contact"
                          value={custContact}
                          onChange={(e) => setCustContact(e.target.value)}
                        />
                        {errors.custContact && (
                          <span className="error-text">
                            {errors.custContact}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Total Person</label>
                        <input
                          className="form-control"
                          type="number"
                          name="person"
                          value={custPerson}
                          onChange={(e) => setCustPerson(e.target.value)}
                        />
                        {errors.custContact && (
                          <span className="error-text">
                            {errors.custPerson}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div class="form-group">
                        <label for="name">Message</label>
                        <textarea
                          class="form-control"
                          name="message custMessage"
                          id="custMessage1"
                          value={custMessage}
                          rows={6}
                          onChange={(e) => setCustMessage(e.target.value)}
                        ></textarea>
                        {errors.custMessage && (
                          <span className="error-text">
                            {errors.custMessage}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div class="form-btn">
                        <button className="booking-btn" onClick={ServForm}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 booking-content">
              <div className="booking-right">
                <div className="bookingsec-title">
                  <span className="sub-title">THE VISAVA RESORT</span>
                  <h2>Contact Us</h2>
                </div>
                <h4>Address</h4>
                <p>
                  The Visawa Resort,Balasaheb Mhatre Collage
                  Rd,Badlapur.Maharashtra 421503
                </p>

                <h4 className="mt-3">Contact</h4>
                <p>
                  <a href="tel:+91 8850340456">+91 8850340456</a>
                  <br />
                  <a href="tel:+91 9022481832">+91 9022481832</a>
                </p>

                <h4 className="mt-3">Mail</h4>
                <p>
                  <a href="mailto:visavafarmhouse@gmail.com">
                    visavafarmhouse@gmail.com
                  </a>
                </p>
                {/* <h4 className="mt-3">Times</h4>
                <p>
                  Mon to Fri : 8.00am to 5.00 pm <br />
                  Sat : 8.00am to 3.00 pm
                  <br /> Sun : closed
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== Footer ========== */}
      <Footer />
    </>
  );
};

export default Home;
