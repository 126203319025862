import React from "react";
import "../Css/RightSideIcon.css";
import { GrFacebookOption, GrInstagram } from "react-icons/gr";
import { RiWhatsappFill } from "react-icons/ri";
import { AiOutlineYoutube } from "react-icons/ai";
import { IoCall } from "react-icons/io5";

const RightSideIcon = () => {
  return (
    <>
      <div class="rightIcon d-md-block d-none">
        <div class="rightIcon-wrap">
          <div class="rightIcon-list">
            <ul>
              <li>
                <a href="https://www.facebook.com/thevisawaresort" target="blank">
                  <i class="facebook">
                    <GrFacebookOption />
                  </i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/thevisawaresort/" target="blank">
                  <i class="instagram">
                    <GrInstagram />
                  </i>
                </a>
              </li>
              <li>
                <a href="https://wa.me/+919022481832?text=" target="blank">
                  <i class="whatsapp">
                    <RiWhatsappFill />
                  </i>
                </a>
              </li>
              <li>
                <a href="tel:+919022481832" target="blank">
                  <i class="calling">
                    {" "}
                    <IoCall />
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="Right_Icon d-md-none d-block">
        <ul>
          <li>
            <a href="https://www.facebook.com/thevisawaresort" target="blank">
              <i class="facebook">
                <GrFacebookOption />
              </i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/thevisawaresort/" target="blank">
              <i class="instagram">
                <GrInstagram />
              </i>
            </a>
          </li>
          <li>
            <a href="https://wa.me/+919022481832?text=" target="blank">
              <i class="whatsapp">
                <RiWhatsappFill />
              </i>
            </a>
          </li>
          <li>
            <a href="tel:++919022481832" target="blank">
              <i class="calling">
                {" "}
                <IoCall />
              </i>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default RightSideIcon;
