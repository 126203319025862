import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import NavBar from './Components/NavBar';
import About from './Pages/About';
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';
import Room from './Pages/Room';
import RoomDetail from './Pages/RoomDetail';
import Amenities from './Pages/Amenities';
import RightIcon from './Components/RightSideIcon';
import RoomData from './Components/RoomData';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <RightIcon/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Room" element={<Room />} />
          <Route path="/Amenities" element={<Amenities />} />
          <Route path="/Contact" element={<Contact />} />
          {RoomData.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.Slugs}
              element={<RoomDetail/>}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
