import React, { useState } from 'react';
import "../Css/Gallery.css";
import Footer from "../Components/Footer";
import { MetaTags } from 'react-meta-tags';
import Zoom from "react-reveal/Zoom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { TiEyeOutline } from "react-icons/ti";
import Gallery1 from "../img/gallery/gallery1.jpg";
import Gallery2 from "../img/gallery/gallery2.jpg";
import Gallery3 from "../img/gallery/gallery3.jpg";
import Gallery4 from "../img/gallery/gallery4.jpg";
import Gallery5 from "../img/gallery/gallery5.jpg";
import Gallery6 from "../img/gallery/gallery6.jpg";
import Gallery7 from "../img/gallery/gallery7.jpg";
import Gallery8 from "../img/gallery/gallery8.jpg";
import Gallery9 from "../img/gallery/gallery9.jpg";
import Gallery10 from "../img/gallery/gallery10.jpg";
import Gallery11 from "../img/gallery/gallery11.jpg";
import Gallery12 from "../img/gallery/gallery12.jpg";


const Gallery =() => {
    // ========== home Gallery ========
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
  
    const images = [Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6, Gallery7, Gallery8, Gallery9, Gallery10, Gallery11, Gallery12];
  
    const openLightbox = (index) => {
      setPhotoIndex(index);
      setLightboxOpen(true);
    };
  
    const closeLightbox = () => {
      setLightboxOpen(false);
    };
  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>The Visawa Resort | DeluxeRoom</title>
        <meta name="description" content="" />
        <meta property="og:title" content="The Visawa Resort | DeluxeRoom" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="The Visawa Resort | DeluxeRoom" />
        <link rel="canonical" href="https://oceandelight.co.in/" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== Gallery Banner ==== */}
      <div className="Gallery-banner">
      <div className="GalleryBanner-Txt">
        <h1 className="d-none">Gallery</h1>
        <Zoom><h2>Gallery</h2></Zoom>
        </div>
      </div>
      {/* =========== Gallery Section =========== */}
      <section className="gallery-images">
        <div className="container">
        <div class="headTitle">
          <h6>Images</h6>
            <h2>Image Gallery</h2>
            <p>
            Visawa Resort's gallery provides a visual narrative of our enchanting retreat, offering a captivating showcase of the luxurious accommodations, scenic surroundings, and vibrant experiences that await our guests.
            </p>
          </div>
          <div className="row">
          {images.map((image, index) => (
            <div className="col-lg-4 col-sm-6 mb-3" key={index}>
              <div className="home_gallery" onClick={() => openLightbox(index)}>
                <img
                  src={image}
                  alt=""
                />
              </div>
            </div>
            ))}
            {lightboxOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={closeLightbox}
                onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
              />
            )}
            </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Gallery;
