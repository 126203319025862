import React from "react";
import "../Css/Footer.css";
import { Link } from "react-router-dom";
import Logo from "../img/logo2.png";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="footer-about">
                <div className="footer-logo">
                  <img src={Logo} width={180} alt="logo" />
                </div>
                <div className="footer-desc">
                  <p>At the Visawa Resort, we are dedicated to providing exceptional experiences for our guests. Situated in the serene surroundings of Badlapur, our resort offers a perfect blend of tranquility and luxury.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="footer-link">
                <div className="footer-head">
                  <h4>Useful Link</h4>
                </div>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/About">About</Link>
                  </li>
                  <li>
                    <Link to="/Room">Room</Link>
                  </li>
                  <li>
                    <Link to="/Gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="https://bookings.asiatech.in/?page=7172&type=website">Book Now</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="footer-address">
                <div className="footer-head">
                  <h4>Get in Touch</h4>
                </div>
                <ul>
                  <li>
                    <i className="fa fa-map-marker" />
                    <span>
                    The Visawa Resort, Balasaheb Mhatre Collage Rd, Badlapur.Maharashtra 421503
                    </span>
                  </li>
                  <li className="mt-2">
                    <i className="fa fa-phone" />{" "}
                    <span>
                      <a href="tel:+9190224 81832">+91 8850340456</a> / 
                      <a href="tel:9022481832">+91 9022481832</a>
                    </span>
                  </li>
                  <li className="mt-2">
                    <i className="fa fa-envelope" />{" "}
                    <span>
                      <a href="mailto:visavafarmhouse@gmail.com">
                      visavafarmhouse@gmail.com
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="footer-map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3769.1080966721565!2d73.279089!3d19.146745!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7f2a0312f473d%3A0xcaca56ceaecea4b9!2sThe%20Visawa%20Resort!5e0!3m2!1sen!2sin!4v1701857671388!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="Footer_copyRight">
          <div className="container">
            <div className="row">
              <div class="col-md-6">
                <div class="copyright">
                  <p>
                    Copyright © 2023 All Rights Reserved :{" "}
                    <span>The Visawa Resort</span>{" "}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="develope-by">
                  <p>
                    Designed by :
                    <a href="https://skdm.in/">
                      {" "}
                      <span>Shree Krishna Digital Marketing</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
