import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";

const EnquiryModal = (props) => {
  const [errors, setErrors] = useState({
    custName: "",
    custEmail: "",
    custContact: "",
    custMessage: "",
  });

  const handleValidation = () => {
    let isValid = true;
    const newErrors = {
      custName: "",
      custEmail: "",
      custContact: "",
      custMessage: "",
    };
    if (!custName.trim()) {
      isValid = false;
      newErrors.custName = "Name is required";
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!custEmail.match(emailRegex)) {
      isValid = false;
      newErrors.custEmail = "Email is not valid";
    }

    if (!custContact.trim()) {
      isValid = false;
      newErrors.custContact = "Phone is required";
    } else if (!/^\d{10}$/.test(custContact)) {
      isValid = false;
      newErrors.custContact = "Phone must have 10 digits";
    }
    if (!custMessage.trim()) {
      isValid = false;
      newErrors.custMessage = "Write a Message";
    }

    setErrors(newErrors);
    return isValid;
  };
  // ============ Mail function ==========
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");
  // ============== Mail Print ======
  const EnquiryForm = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      var body =
      '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#d08b4d">Ravaji Patil Resort</h2><p>Hello Ravaji Patil Resort,</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#d08b4d">' + custName + '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#d08b4d">' + custEmail + '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#d08b4d">' + custContact + '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#d08b4d">' + custMessage + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#d08b4d;padding-left:5px">Ravaji Patil Resort</span>.</p></div></body></html>';
      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          toEmail: "ravajipatilresort@gmail.com",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName: "ravaji patil resort",
          accountLeadSource: "https://ravajipatilresort.com",
          accountLeadName: custName,
          // accountLeadEmail: custEmail,
        },
        function (dataa, status) {
          console.log("data :" + dataa);
          console.log("name:" + custName);
          console.log("name:" + custEmail);
        }
      );

      alert("Your Form has Submitted Our team will contact with You  soon.");
      e.preventDefault();
      return false;
    }
  };
  return (
    <>
    <div className="EnquiryModal-form">
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Enquiry Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contact-form">
          <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      onChange={(e) => setCustName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email Address"
                      onChange={(e) => setCustEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="form-group">
                    <input
                      type="number"
                      name="contact"
                      placeholder="Your Contact Number"
                      className="form-control"
                      onChange={(e) => setCustContact(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3">
                <div className="form-group">
                <textarea
                  name="message"
                  cols={30}
                  rows={5}
                  className="form-control"
                  placeholder="Write Your Message"
                  onChange={(e) => setCustMessage(e.target.value)}
                />
              </div>
                </div>
              </div>
              
              <button
                type="submit"
                className="standard-btn"
                onClick={EnquiryForm}
              >
                Submit
              </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} className="standard-btn">Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
    </>
  );
};

export default EnquiryModal;
