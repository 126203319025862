import React, {useState} from "react";
import "../Css/RoomDetail.css";
import Footer from "../Components/Footer";
import { Zoom } from "react-reveal";
import { MetaTags } from "react-meta-tags";
import RoomData from "../Components/RoomData";
import {useLocation } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const RoomDetail = () => {
// ======= Fetch Services with Slugs in same page =======
const search = useLocation();
const Path = search.pathname;
const RoomDetail = RoomData.find((e) => e.Slugs == Path);
 // ========== home Gallery ========
 const [lightboxOpen, setLightboxOpen] = useState(false);
 const [photoIndex, setPhotoIndex] = useState(0);


 const openLightbox = (index) => {
   setPhotoIndex(index);
   setLightboxOpen(true);
 };

 const closeLightbox = () => {
   setLightboxOpen(false);
 };

  return (
    <>
          {/* ========= Seo start========== */}
          <MetaTags>
        <title>The Visawa Resort | DeluxeRoom</title>
        <meta name="description" content="" />
        <meta property="og:title" content="The Visawa Resort | DeluxeRoom" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="The Visawa Resort | DeluxeRoom" />
        <link rel="canonical" href="https://oceandelight.co.in/" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== Room Banner ======== */}
      <div className="RoomDetail-banner">
        <div className="RoomBanner_Txt">
        <h1 className="d-none">Rooms</h1>
          <Zoom>
            <h2>Rooms</h2>
          </Zoom>
          {/* <p>
          Unwind with unmatched elegance at the best resort near the beach in Bhogave.
          </p> */}
        </div>
      </div>
    {/* ===== Room Details ======= */}
      <section className="room-Detail-page">
        <div className="container">
          <div className="row">
            <div className="col-12">
                <h2>{RoomDetail.Header}</h2>
                <p>{RoomDetail.Para1}</p>
                <p>{RoomDetail.Para2}</p>
            </div>
          </div>
        </div>
      </section>
    {/* =========== Gallery Section =========== */}
    <section className="gallery-images">
        <div className="container">
        <div class="headTitle">
          <h6>Room</h6>
            <h2>Room Images Gallery</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam hic minima quis, aliquam officia in!
            </p>
          </div>
          <div className="row">
          {RoomDetail.imgData.map((imgValue, index) => (
            <div className="col-lg-4 col-sm-6 mb-3" key={index}>
                    <div className="home_gallery" onClick={() => openLightbox(index)}>
                      <img src={imgValue.roomImg} />
                      </div>
                    </div>
                ))}
            {lightboxOpen && (
              <Lightbox
                mainSrc={RoomDetail.imgData[photoIndex]}
                nextSrc={RoomDetail.imgData[(photoIndex + 1) % RoomDetail.imgData.length]}
                prevSrc={RoomDetail.imgData[(photoIndex + RoomDetail.imgData.length - 1) % RoomDetail.imgData.length]}
                onCloseRequest={closeLightbox}
                onMovePrevRequest={() => setPhotoIndex((photoIndex + RoomDetail.imgData.length - 1) % RoomDetail.imgData.length)}
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % RoomDetail.imgData.length)}
              />
            )}
            </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default RoomDetail;
